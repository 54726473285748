import React from "react";
import { Layout, SEO } from '../components/structure';
import { PartnerGrid, Hero, Content } from "../components/blocks"
import StaggeredColumn from "../components/blocks/StaggeredColumns"
import GatsbyImage from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import english from "../../content/translations/en-CA/partners.json"
import french from "../../content/translations/fr-CA/partners.json"



const LoyaltyPartnersPage = () => {

  let lang = english;

  const data = useStaticQuery(graphql`
  query {

    finance_01: file(base: { eq: "Aimia.png" }) {
      ...FinanceLogoCanvas
    }
    finance_02: file(base: { eq: "AmericanExpress.png" }) {
      ...FinanceLogoCanvas
    }
    finance_03: file(base: { eq: "AMME.png" }) {
      ...FinanceLogoCanvas
    }
    finance_04: file(base: { eq: "Bilt.png" }) {
      ...FinanceLogoCanvas
    }
    finance_05: file(base: { eq: "Chase.png" }) {
      ...FinanceLogoCanvas
    }
    finance_06: file(base: { eq: "Citi.png" }) {
      ...FinanceLogoCanvas
    }
    finance_07: file(base: { eq: "Mashreq-Finance.png" }) {
      childImageSharp {
        fluid(maxWidth: 250, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    finance_08: file(base: { eq: "VirginRed.png" }) {
      ...FinanceLogoCanvas
    }

    merchant_01: file(base: { eq: "FTD_2023.png" }) {
      ...MerchantLogoCanvas
    }
    merchant_02: file(base: { eq: "GYG.png" }) {
      ...MerchantLogoCanvas
    }
    merchant_03: file(base: { eq: "HomeChef.png" }) {
      ...MerchantLogoCanvas
    }
    merchant_04: file(base: { eq: "Lyft.png" }) {
      ...MerchantLogoCanvas
    }
    merchant_05: file(base: { eq: "Marathon.png" }) {
      ...MerchantLogoCanvas
    }
    merchant_06: file(base: { eq: "Rocket.png" }) {
      ...MerchantLogoCanvas
    }
    merchant_07: file(base: { eq: "TicketMaster.png" }) {
      ...MerchantLogoCanvas
    }

  }
  `);

  return(
  <Layout>
      <SEO title={ lang.meta.title } />
      <Hero
        title={lang.hero.title}
        subtitle={ lang.hero.subtitle }
      />
      <Content>
        <h2 className={"kl"}>{ lang.content.loyalty_partners_header }</h2>
        <PartnerGrid featured={false} />
      </Content>
      <Content partnerMargin>
        <h2 className={"kl"} style={{marginTop: "50px"}}>{ lang.content.financial_partners_header }</h2>
        <div className={"partner-icon-list"}>
          <GatsbyImage fixed={data['finance_01'].childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} alt={'Icon 1'} />
          <GatsbyImage fixed={data['finance_02'].childImageSharp.fluid} imgStyle={{ objectFit: 'contain'}} alt={'Icon 1'} />
          <GatsbyImage fixed={data['finance_05'].childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} alt={'Icon 1'} />
          <GatsbyImage fixed={data['finance_06'].childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} alt={'Icon 1'} />
          <GatsbyImage fixed={data['finance_07'].childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} alt={'Icon 1'} style={{flexBasis: '300px'}}/>
        </div>
      </Content>
      
      <Content>
      <h2 className={"kl"} style={{margin: "6.5rem 0 1.45rem 0"}}>{ lang.content.merchant_partners_header }</h2>
      <div className={"partner-icon-list"}>
        <GatsbyImage fixed={data['merchant_01'].childImageSharp.fluid} imgStyle={{ objectFit: 'contain',objectPosition: 'center', maxWidth: '200px' }} alt={'Icon 1'} />
        <GatsbyImage fixed={data['merchant_03'].childImageSharp.fluid} imgStyle={{ objectFit: 'contain',objectPosition: 'center', maxWidth: '200px' }} alt={'Icon 1'} />
        <GatsbyImage fixed={data['merchant_04'].childImageSharp.fluid} imgStyle={{ objectFit: 'contain',objectPosition: 'center', maxWidth: '200px' }} alt={'Icon 1'} />
        <GatsbyImage fixed={data['merchant_05'].childImageSharp.fluid} imgStyle={{ objectFit: 'contain',objectPosition: 'center', maxWidth: '200px' }} alt={'Icon 1'} />
        <GatsbyImage fixed={data['merchant_06'].childImageSharp.fluid} imgStyle={{ objectFit: 'contain',objectPosition: 'center', maxWidth: '200px' }} alt={'Icon 1'} />
        <GatsbyImage fixed={data['merchant_07'].childImageSharp.fluid} imgStyle={{ objectFit: 'contain',objectPosition: 'center', maxWidth: '200px' }} alt={'Icon 1'} />
      </div>
      </Content>
    </Layout>
  )
  
  };

  export const merchquery = graphql`
  fragment MerchantLogoCanvas on File {
    childImageSharp {
      fluid(maxWidth: 150, webpQuality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const financequery = graphql`
fragment FinanceLogoCanvas on File {
  childImageSharp {
    fluid(maxWidth: 200, webpQuality: 100) {
      ...GatsbyImageSharpFluid_withWebp
    }
  }
}
`;

export default LoyaltyPartnersPage;
